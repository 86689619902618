import request from "@/utils/request";
import API from "@/services/api";

interface fetchCreateOrderParams {
    contractId: number
}
export function fetchCreateOrder(data: fetchCreateOrderParams){
    return request({
        url: API.user.order.create,
        method: "GET",
        params: data,
    })
}

export interface fetchOrderListParams {
    page: number,
    perPage: number,
    status?: number,
}

export function fetchOrderList(data: fetchOrderListParams) {
    return request({
        url: API.user.order.list,
        method: "GET",
        params: data,
    })
}

interface fetchOrderDetailParams {
    id: number,
}
export function fetchOrderDetail(data: fetchOrderDetailParams){
    return request({
        url: API.user.order.detail,
        method: "GET",
        params: data,
    })
}

interface fetchReportMoneyParams {
    id: number
}

export function fetchReportMoney(data: fetchReportMoneyParams) {
    return request({
        url: API.user.order.reportMoney,
        method: "GET",
        params: data,
    })
}
