import {Md5} from 'ts-md5';

const postSign = (data: object): string => {
    let str: string = ''
    let i: number = 0
    const obj: any = objKeySort(data)
    const len = Object.keys(obj).length

    if (len < 1) return ''

    for (const dataKey in obj) {
        str += dataKey + "=" + obj[dataKey]
        str += i === len - 1 ? "td-ask" : "|"
        i = i + 1;
    }

    return Md5.hashStr(str)
}

const objKeySort = (obj: any): object => {
    const newObjKey = Object.keys(obj).sort()
    // const newObjKey = Object.keys(obj).sort().reverse()

    const newObj: any = {}

    for (let i = 0; i < newObjKey.length; i++) {
        newObj[newObjKey[i]] = Array.isArray(obj[newObjKey[i]]) ? JSON.stringify(obj[newObjKey[i]]) : obj[newObjKey[i]]
    }

    return newObj
}

export {
    postSign,
}
