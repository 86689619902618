import request from "@/utils/request";
import API from "@/services/api";

export function fetchBannerList() {
    return request({
        url: API.home.bannerList,
        method: "GET",
    })
}
export interface fetchGoodStoreListParams {
    page: number
    perPage: number
}
export function fetchGoodStoreList(data: fetchGoodStoreListParams) {
    return request({
        url: API.home.goodStoreList,
        method: "GET",
        params: data,
    })
}
export interface fetchAuthStoreListParams {
    page: number
    perPage: number
}
export function fetchAuthStoreList(data: fetchAuthStoreListParams) {
    return request({
        url: API.home.authStoreList,
        method: "GET",
        params: data,
    })
}

export function fetchPositionList(){
    return request({
        url: API.home.positionList,
        method: "GET",
    })
}
