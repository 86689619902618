import request from "@/utils/request";
import API from "@/services/api";

interface storeListParams {
    page: number,
    perPage: number,
}
export function fetchStoreList(data: storeListParams){
    return request({
        url: API.store.list,
        method: "GET",
        params: data,
    })
}

interface storeDetailParams {
    id: number,
}

export function fetchStoreDetail(data: storeDetailParams) {
    return request({
        url: API.store.detail,
        method: "GET",
        params: data,
    })
}

interface fetchDictListParams {
    cityId?: number,
}

export function fetchDictList(data: fetchDictListParams) {
    return request({
        url: API.store.dictList,
        method: "GET",
        params: data,
    })
}
