interface menuItem {
    id: number,
    name: string,
    path: string,
    label: string,
    is_login: boolean,
}

const menuList: menuItem[] = [
    {
        id: 1,
        name: "首页",
        path: "/",
        label: "",
        is_login: false,
    },
    {
        id: 2,
        name: "仓库出租",
        path: "/store/list",
        label: "优惠",
        is_login: false,
    },
    {
        id: 3,
        name: "厂房出租",
        path: "/store/shed",
        label: "",
        is_login: false,
    },
    {
        id: 4,
        name: "委托找仓",
        path: "/other/entrust-store",
        label: "",
        is_login: false,
    },
    {
        id: 5,
        name: "加盟申请",
        path: "/other/apply-join",
        label: "",
        is_login: false,
    },
    {
        id: 6,
        name: "我的订单",
        path: "/user-center/order",
        label: "",
        is_login: true,
    }
]

interface userMenuItem {
    name: string
    router: string
}
const userMenuList: userMenuItem[] = [
    {
        name: "个人信息",
        router: "/user-center/info/view",
    },
    {
        name: "合同签署",
        router: "/user-center/contract",
    },
    {
        name: "我的收藏",
        router: "/user-center/collect",
    },
    {
        name: "我的订单",
        router: "/user-center/order",
    },
    {
        name: "我的账单",
        router: "/user-center/bill",
    },
    {
        name: "发票管理",
        router: "/user-center/invoice",
    }
]

interface positionCityItem {
    id: number
    label: string
}

interface positionItem{
    name: string
    children: positionCityItem[]
}


export {
    menuItem,
    menuList,
    userMenuItem,
    userMenuList,
    positionItem,
}
