import request from "@/utils/request";
import API from "@/services/api";

export function getProvinceList() {
    return request({
        url: API.global.provinceList,
        method: "GET",
    })
}

interface cityParams {
    provinceId: number,
}
export function getCityList(data: cityParams) {
    return request({
        url: API.global.cityList,
        method: "GET",
        params: data,
    })
}

interface areaParams {
    cityId: number,
}
export function getAreaList(data: areaParams) {
    return request({
        url: API.global.areaList,
        method: "GET",
        params: data
    })
}

interface fieldDictionaryParams {
    type?: string,
}
export function fieldDictionary(data: fieldDictionaryParams) {
    return request({
        url: API.global.fieldDictionary,
        method: "GET",
        params: data,
    })
}

// 获取阿里OSS文件上传信息
export function fetchAliOSSInfo() {
    return request({
        url: API.global.aliOSSSign,
        method: "GET",
    })
}

export interface fetchSetCityParams {
    cityId: number
    cityName: string
}

export function fetchSetCity(data: fetchSetCityParams) {
    return request({
        url: API.global.setCity,
        method: "POST",
        data: data,
    })
}
