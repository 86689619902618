import {CONFIG} from "@/configs/configs";

const download = (platform: string) => {
    console.log(CONFIG.AndroidDownUrl)
    switch (platform) {
        case 'android':
            window.open(CONFIG.AndroidDownUrl, '_blank')
            break;
        case 'iphone':
            window.open(CONFIG.iPhoneDownUrl, '_blank')
            break;
        default:
            window.open(CONFIG.AndroidDownUrl, '_blank')
            break;
    }
}

export {
    download,
}
