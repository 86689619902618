import request from "@/utils/request";
import API from "@/services/api";

//业务状态：1-已上架 2-已下架 3-部分出租 4-全部出租 5-已禁用，多个状态用逗号分隔传递
export interface fetchCollectListParams {
    bizStatus: string,
    page: number,
    perPage: number,
    bizName?: string,
}

export function fetchCollectList(data: fetchCollectListParams) {
    return request({
        url: API.user.collect.list,
        method: "GET",
        params: data,
    })
}

export interface fetchSaveCollectParams {
    id: number,
}

export function fetchSaveCollect(data: fetchSaveCollectParams) {
    return request({
        url: API.user.collect.save,
        method: "POST",
        data: data,
    })
}

export interface fetchCancelCollectParams {
    id: number,
}

export function fetchCancelCollect(data: fetchCancelCollectParams) {
    return request({
        url: API.user.collect.cancel,
        method: "POST",
        data: data,
    })
}
