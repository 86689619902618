import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router/router'
import store from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store);
app.use(router);
app.mount("#app");
