import wx from "@/assets/img/wencang.png"
const CONFIG = {
    HOST: '',
    baseURL: '',
    env: '',
    tokenKey:  "wc-user-token",
    tokenKeyExpire: "wc-user-token-expire",
    tokenKeyExpireTime: 60 * 60 * 24 * 15,
    smsCodeExpireTime: 60 * 2,
    position: "wc-user-position",
    UnityStoreUrl: "https://www.51wencang.com/vr/index.html",
    wxPublicImage: wx,
    APPDownloadImage: wx,
    serviceImage: wx,
    AndroidDownUrl: 'https://qyabpackage.himymeta.com/wencang/apk/wencang_v5.apk',
    iPhoneDownUrl: 'https://apps.apple.com/cn/app/%E9%97%AE%E4%BB%93/id6471396040',
}

const HOST_CONFIG = {
    "development": "https://ask-dev.372163.com",
    "testing": "https://ask-test.372163.com",
    "production": "https://backend.51wencang.com",
}

const RunEnv: string = process.env.VUE_APP_RUN_ENV ?? "development"
console.log("CONFIG-环境变量",process.env)
console.log('CONFIG-运行环境变量',RunEnv)

switch (RunEnv) {
    case "production":
        CONFIG.HOST = HOST_CONFIG.production
        CONFIG.baseURL = '/'
        CONFIG.env = 'prod'
        break;
    case "testing":
        CONFIG.HOST  = HOST_CONFIG.testing
        CONFIG.baseURL = '/'
        CONFIG.env = 'test'
        break;
    case "development":
        CONFIG.HOST  = HOST_CONFIG.development
        CONFIG.baseURL = '/'
        CONFIG.env = 'dev'
        break;
    default:
        CONFIG.HOST  = HOST_CONFIG.development
        CONFIG.baseURL = '/'
        CONFIG.env = 'dev'
        break;
}

export {
    CONFIG
};
