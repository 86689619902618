import userIcon from "@/assets/img/user-icon-1.png";
import userIconActive from "@/assets/img/user-icon-2.png";
import contractIcon from "@/assets/img/contract-icon-1.png";
import contractIconActive from "@/assets/img/contract-icon-2.png";
import collectIcon from "@/assets/img/collect-icon-1.png";
import collectIconActive from "@/assets/img/collect-icon-2.png";
import billIcon from "@/assets/img/bill-icon-1.png";
import billIconActive from "@/assets/img/bill-icon-2.png";
import orderIcon from "@/assets/img/order-icon-1.png";
import orderIconActive from "@/assets/img/order-icon-2.png";
import invoiceIcon from "@/assets/img/invoice-icon-1.png";
import invoiceIconActive from "@/assets/img/invoice-icon-2.png";

import UserCenterInfoIndexView from "@/views/UserCenter/Info/UserCenterInfoIndexView.vue";
import UserCenterContractView from "@/views/UserCenter/Contract/UserCenterContractView.vue";
import UserCenterCollectView from "@/views/UserCenter/Collect/UserCenterCollectView.vue";
import UserCenterBillIndexView from "@/views/UserCenter/Bill/UserCenterBillIndexView.vue";
import UserCenterOrderIndexView from "@/views/UserCenter/Order/UserCenterOrderIndexView.vue";
import UserCenterInvoiceIndexView from "@/views/UserCenter/Invoice/UserCenterInvoiceIndexView.vue";

interface menuItemConf {
    id: number,
    name: string,
    routerName: string,
    router: string,
    defaultIcon: string,
    activeIcon: string,
    component: object,
    children: menuItemConf[],
    moduleName: string,
}

const menuListData: menuItemConf[] = ([
    {
        id: 1,
        name: '个人信息',
        routerName: 'user-center-info',
        router: '/user-center/info',
        defaultIcon: userIcon,
        activeIcon: userIconActive,
        component: UserCenterInfoIndexView,
        moduleName: 'info',
        children: [
            {
                id: 11,
                name: '个人信息',
                routerName: 'user-center-info',
                router: '/user-center/info/view',
                defaultIcon: userIcon,
                activeIcon: userIconActive,
                component: UserCenterInfoIndexView,
                children: [],
                moduleName: 'info',
            },
            {
                id: 111,
                name: '个人信息',
                routerName: 'user-center-info',
                router: '/user-center/info/edit',
                defaultIcon: userIcon,
                activeIcon: userIconActive,
                component: UserCenterInfoIndexView,
                children: [],
                moduleName: 'info',
            },
        ],
    },
    {
        id: 3,
        name: '合同签署',
        routerName: 'user-center-contract',
        router: '/user-center/contract',
        defaultIcon: contractIcon,
        activeIcon: contractIconActive,
        component: UserCenterContractView,
        children: [],
        moduleName: 'contract',
    },
    {
        id: 4,
        name: '我的收藏',
        routerName: 'user-center-collect',
        router: '/user-center/collect',
        defaultIcon: collectIcon,
        activeIcon: collectIconActive,
        component: UserCenterCollectView,
        children: [],
        moduleName: 'collect',
    },
    {
        id: 5,
        name: '我的订单',
        routerName: 'user-center-order',
        router: '/user-center/order',
        defaultIcon: orderIcon,
        activeIcon: orderIconActive,
        component: UserCenterOrderIndexView,
        moduleName: 'order',
        children: [
            {
                id: 55,
                name: '订单列表',
                routerName: 'user-center-order-list',
                router: '/user-center/order/list',
                defaultIcon: orderIcon,
                activeIcon: orderIconActive,
                component: UserCenterOrderIndexView,
                children: [],
                moduleName: 'order',
            },
            {
                id: 555,
                name: '订单详情',
                routerName: 'user-center-order-detail',
                router: '/user-center/order/detail',
                defaultIcon: orderIcon,
                activeIcon: orderIconActive,
                component: UserCenterOrderIndexView,
                children: [],
                moduleName: 'order',
            }
        ],
    },
    {
        id: 6,
        name: '我的账单',
        routerName: 'user-center-bill',
        router: '/user-center/bill',
        defaultIcon: billIcon,
        activeIcon: billIconActive,
        component: UserCenterBillIndexView,
        moduleName: 'bill',
        children: [
            {
                id: 66,
                name: '账单详情',
                routerName: 'user-center-bill-list',
                router: '/user-center/bill/list',
                defaultIcon: userIcon,
                activeIcon: userIconActive,
                component: UserCenterBillIndexView,
                children: [],
                moduleName: 'bill',
            },
            {
                id: 666,
                name: '账单详情',
                routerName: 'user-center-bill-detail',
                router: '/user-center/bill/detail',
                defaultIcon: userIcon,
                activeIcon: userIconActive,
                component: UserCenterBillIndexView,
                children: [],
                moduleName: 'bill',
            },
            {
                id: 6666,
                name: '账单支付',
                routerName: 'user-center-bill-pay',
                router: '/user-center/bill/pay',
                defaultIcon: userIcon,
                activeIcon: userIconActive,
                component: UserCenterBillIndexView,
                children: [],
                moduleName: 'bill',
            },
        ],
    },
    {
        id: 7,
        name: '发票管理',
        routerName: 'user-center-invoice',
        router: '/user-center/invoice',
        defaultIcon: invoiceIcon,
        activeIcon: invoiceIconActive,
        component: UserCenterInvoiceIndexView,
        children: [],
        moduleName: 'invoice',
    }
])

export {
    menuItemConf,
    menuListData,
}
