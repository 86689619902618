import request from "@/utils/request";
import API from "@/services/api";

interface addEntrustStoreParams {
    area: number, //求租面积
    contact: string, //联系方式
    desc: string, //描述
    name: string, //姓名
    position: string //地址
}

export function addEntrustStore(data: addEntrustStoreParams) {
    return request({
        url: API.other.entrustStore,
        method: "POST",
        data: data,
    })
}

interface addApplyJoinParams {
    name?: string,
    contact: string,
    smsCode: string,
    area?: number,
    position?: string,
    address?: string,
}

export function addApplyJoin(data: addApplyJoinParams) {
    return request({
        url: API.other.applyJoin,
        method: "POST",
        data: data,
    })
}
