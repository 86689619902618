import { createStore } from 'vuex'
import global from "@/store/global";
import home from "@/store/modules/home";

const state = () => {
  return {
    sign: '',
  }
}

const mutations = {
}

export default createStore({
  modules: {
    global,
    home,
  },
  state,
  mutations,
  strict: false,
})
