import {CONFIG} from "@/configs/configs";

export interface userInfoParams {
    id: number
    clientNo: string
    companyName: string
    creditCode: string
    legalPerson: string
    legalPersonContact: string
    head: string
    headContact: string
    validDocuments: string
    auditStatus: number
    status: number
    authStatus: number
    addUserId: number
    addUserName: string
    operatorId: number
    operatorName: string
    auditUserId: number
    auditUserName: string
    auditTime: any
    accountNo: string
    bankName: string
    isDel: number
    addTime: string
    updateTime: string
    procedureNo: string
    procedureStatus: number
    reason: string
    authStatusCn: string
    auditStatusCn: string
    statusCn: string
    username: string
}

const state = () => {
    const userInfoData: userInfoParams | null = null

    return {
        userInfo: userInfoData,
        token: null,
        position: null,
    }
}

const mutations = {
    firstGetToken(state: any) {
        const userToken: string | null = localStorage.getItem(CONFIG.tokenKey)
        const userTokenExpire: string | null = localStorage.getItem(CONFIG.tokenKeyExpire)
        const date: Date = new Date()

        if (userToken !== null && userToken.length > 0 && userTokenExpire !== null && date.getTime() / 1000 < Number(userTokenExpire)) {
            // token存在，验证是否有效从逻辑层处理
            state.token = userToken
        }
    },
    updatePosition(state: any, payload: any) {
        state.position = payload
    },
    login(state: any, payload: any) {
        state.token = payload.token
        state.userInfo = JSON.parse(JSON.stringify(payload.userInfo))

        const nowTime: number = Math.floor(new Date().getTime() / 1000)
        const expireTime: string = nowTime + CONFIG.tokenKeyExpireTime + ""
        localStorage.setItem(CONFIG.tokenKey, payload.token)
        localStorage.setItem(CONFIG.tokenKeyExpire, expireTime)
    },
    updateUserInfo(state: any, payload: any) {
        console.log('更新了全局的用户信息')
        state.userInfo = JSON.parse(JSON.stringify(payload.userInfo))
    },
    autoLogin(state: any, payload: any) {
        // 本地自动登录专用方法
        state.token = payload.token
        state.userInfo = JSON.parse(JSON.stringify(payload.userInfo))
    },
    logout(state: any) {
        state.token = null
        state.userInfo = null

        localStorage.removeItem(CONFIG.tokenKey)
        localStorage.removeItem(CONFIG.tokenKeyExpire)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
}
