import request from "@/utils/request";
import API from "@/services/api";

interface fetchContractSignUrlParams {
    id: number,
}
export function fetchContractSignUrl(data: fetchContractSignUrlParams) {
    return request({
        url: API.user.contract.sign,
        method: "GET",
        params: data,
    })
}

export function fetchContractAuthUrl() {
    return request({
        url: API.user.contract.auth,
        method: "GET",
    })
}

//合同状态：1、待签署 2、待生效 3、生效中 4、已作废 5、异常终止
interface fetchContractListParams {
    page: number,
    perPage: number,
    status?: string,
}

export function fetchContractList(data: fetchContractListParams){
    return request({
        url: API.user.contract.list,
        method: "GET",
        params: data,
    })
}

interface fetchContractDetailParams {
    id: number
}

export function fetchContractDetail(data: fetchContractDetailParams) {
    return request({
        url: API.user.contract.detail,
        method: "GET",
        params: data,
    })
}


export function fetchContractSignCallBack(data: any) {
    return request({
        url: API.user.contract.signCallBack,
        params: data,
    })
}

export function fetchContractAuthCallBack(data: any) {
    return request({
        url: API.user.contract.authCallBack,
        params: data,
    })
}
