import request from "@/utils/request";
import API from "@/services/api";

export interface fetchInvoiceListParams {
    page: number,
    perPage: number,
    pagetype: string,
}

export function fetchInvoiceList(data: fetchInvoiceListParams) {
    return request({
        url: API.user.invoice.list,
        method: "GET",
        params: data,
    })
}

export interface fetchInvoiceRecordListParams {
    page: number
    perPage: number
    no?: string //发票号
    orderNo?: string //订单号
    addTimeStart?: string //日期开始
    addTimeEnd?: string //日期结束
}

export function fetchInvoiceRecordList(data: fetchInvoiceRecordListParams) {
    return request({
        url: API.user.invoice.record,
        method: "GET",
        params: data,
    })
}

export interface fetchInvoiceBaseInfoParams {
    pageType: string,
}

export function fetchInvoiceBaseInfo(data: fetchInvoiceBaseInfoParams) {
    return request({
        url: API.user.invoice.info,
        method: "GET",
        params: data,
    })
}

export function fetchInvoiceHeadType() {
    return request({
        url: API.user.invoice.headType,
        method: "GET",
    })
}

export function fetchInvoiceType() {
    return request({
        url: API.user.invoice.invoiceType,
        method: "GET",
    })
}

export interface fetchSaveHeadParams {
    id?: string//选填，填写为编辑
    account: string //账号
    address: string //注册地
    bank: string //开户行
    creditIdentifier: string //统一社会信用代码
    head: string //发票抬头
    headType: number //抬头类型：1企业
    pageType: string
    tel: string //注册固话
    type: number //发票类型：1增值税业务专用发票
}

export function fetchSaveHead(data: fetchSaveHeadParams) {
    return request({
        url: API.user.invoice.save,
        method: "POST",
        data: data,
    })
}

export interface fetchSaveAddressParams {
    id?: string//选填，填写为编辑
    address: string //地址
    name: string //收件人名
    pageType: string
    tel: string //电话号码
    zipcode: string //邮编
}

export function fetchSaveAddress(data: fetchSaveAddressParams) {
    return request({
        url: API.user.invoice.save,
        method: "POST",
        data: data,
    })
}

export interface fetchSaveEmailParams {
    id?: string //选填，填写为编辑
    email: string //邮件地址
    pageType: string
}

export function fetchSaveEmail(data: fetchSaveEmailParams) {
    return request({
        url: API.user.invoice.save,
        method: "POST",
        data: data,
    })
}

export interface fetchDelAddressParams {
    id: number
    pageType: string
}

export function fetchDelAddress(data: fetchDelAddressParams) {
    return request({
        url: API.user.invoice.delete,
        method: "POST",
        data: data,
    })
}

export interface fetchDefaultInfoParams {
    id: number
    pageType: string //head抬头，address地址，email邮件
}
export function fetchDefaultInfo(data: fetchDefaultInfoParams) {
    return request({
        url: API.user.invoice.defaultInfo,
        method: "POST",
        data: data,
    })
}

export interface fetchApplyInvoiceParams {
    id?: string //发票id，编辑时填写
    billDetailIds: string //账单明细id，英文逗号隔开
    billIds: string //账单id，英文逗号隔开
    invoiceAddressId: number //收货地址
    invoiceHeadId: number //抬头
    invoiceEmailId: number //邮件
    invoiceMethod: number //发票性质，1纸质（快递），2电子（邮箱）
    applyRemark: string //开票备注
}
export function fetchApplyInvoice(data: fetchApplyInvoiceParams){
    return request({
        url: API.user.invoice.apply,
        method: "POST",
        data: data,
    })
}

export interface fetchEditApplyRemarkParams {
    applyRemark: string
    id: number
}

export function fetchEditApplyRemark(data: fetchEditApplyRemarkParams) {
    return request({
        url: API.user.invoice.applyRemark,
        method: "POST",
        data: data,
    })
}

export interface fetchInvoiceDetailParams {
    id: number
}

export function fetchInvoiceDetail (data: fetchInvoiceDetailParams) {
    return request({
        url: API.user.invoice.detail,
        method: "GET",
        params: data,
    })
}
