import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/Home/HomeView.vue'
import StoreListView from "@/views/Store/StoreListView.vue"
import StoreDetailView from "@/views/Store/StoreDetailView.vue"
import AppDownloadView from "@/views/Other/AppDownloadView.vue";
import ApplyJoinView from "@/views/Other/ApplyJoinView.vue";
import EntrustStoreView from "@/views/Other/EntrustStoreView.vue";
import UserCenterView from "@/views/UserCenter/UserCenterView.vue";

import UserCenterInfoIndexView from "@/views/UserCenter/Info/UserCenterInfoIndexView.vue";
import UserCenterInfoView from "@/views/UserCenter/Info/UserCenterInfoView.vue";
import UserCenterInfoEditView from "@/views/UserCenter/Info/UserCenterInfoEditView.vue";

import UserCenterContractView from "@/views/UserCenter/Contract/UserCenterContractView.vue";

import UserCenterCollectView from "@/views/UserCenter/Collect/UserCenterCollectView.vue";

import UserCenterBillIndexView from "@/views/UserCenter/Bill/UserCenterBillIndexView.vue";
import UserCenterBillListView from "@/views/UserCenter/Bill/UserCenterBillListView.vue";
import UserCenterBillDetailView from "@/views/UserCenter/Bill/UserCenterBillDetailView.vue";
import UserCenterBillPayView from "@/views/UserCenter/Bill/UserCenterBillPayView.vue";

import UserCenterOrderIndexView from "@/views/UserCenter/Order/UserCenterOrderIndexView.vue";
import UserCenterOrderListView from "@/views/UserCenter/Order/UserCenterOrderListView.vue";
import UserCenterOrderDetailView from "@/views/UserCenter/Order/UserCenterOrderDetailView.vue";

import UserCenterInvoiceIndexView from "@/views/UserCenter/Invoice/UserCenterInvoiceIndexView.vue";
import UserCenterInvoiceView from "@/views/UserCenter/Invoice/UserCenterInvoiceView.vue";
import SignCallback from "@/views/Other/SignCallback.vue";
import MobileSignCallback from "@/views/Other/MobileSignCallback.vue";
import MobileAuthCallback from "@/views/Other/MobileAuthCallback.vue";

import RegisterProtocolView from "@/views/Other/RegisterProtocolView.vue";
import PrivacyProtocolView from "@/views/Other/PrivacyProtocolView.vue";



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/store', // 仓库出租
    name: 'store',
    children: [
      {
        path: '/store/list',
        name: 'store-list',
        component: StoreListView,
      },
      {
        path: '/store/shed',
        name: 'store-shed',
        component: StoreListView,
      },
      {
        path: '/store/detail',
        name: 'store-detail',
        component: StoreDetailView,
      }
    ],
  },
  {
    path: '/other', // 其他页面
    name: 'other',
    children: [
      {
        path: '/other/download-app',
        name: 'other-download-app',
        component: AppDownloadView,
      },
      {
        path: '/other/apply-join',
        name: 'other-apply-join',
        component: ApplyJoinView,
      },
      {
        path: '/other/entrust-store',
        name: 'other-entrust-store',
        component: EntrustStoreView,
      },
      {
        path: '/other/sign-callback',
        name: 'other-sign-callback',
        component: SignCallback,
      },
      {
        path: '/other/register-protocol',
        name: 'other-register-protocol',
        component: RegisterProtocolView,
      },
      {
        path: '/other/privacy-protocol',
        name: 'other-privacy-protocol',
        component: PrivacyProtocolView,
      },
      {
        path: '/other/mobile-sign-callback',
        name: 'other-mobile-sign-callback',
        component: MobileSignCallback,
      },
      {
        path: '/other/mobile-auth-callback',
        name: 'other-mobile-auth-callback',
        component: MobileAuthCallback,
      }
    ]
  },
  {
    path: '/user-center', // 用户中心
    name: 'user-center',
    component: UserCenterView,
    children: [
      {
        path: '/user-center/info',
        name: 'user-center-info',
        component: UserCenterInfoIndexView,
        redirect: "/user-center/info/view",
        meta: {
          module_name: 'info',
        },
        children: [
          {
            path: '/user-center/info/view',
            name: 'user-center-info-view',
            component: UserCenterInfoView,
            meta: {
              module_name: 'info',
            },
          },
          {
            path: '/user-center/info/edit',
            name: 'user-center-info-edit',
            component: UserCenterInfoEditView,
            meta: {
              module_name: 'info',
            },
          },
        ],
      },
      {
        path: '/user-center/contract',
        name: 'user-center-contract',
        component: UserCenterContractView,
        meta: {
          module_name: 'contract',
        },
      },
      {
        path: '/user-center/collect',
        name: 'user-center-collect',
        component: UserCenterCollectView,
        meta: {
          module_name: 'collect',
        },
      },
      {
        path: '/user-center/bill',
        name: 'user-center-bill',
        component: UserCenterBillIndexView,
        redirect: '/user-center/bill/list',
        meta: {
          module_name: 'bill',
        },
        children: [
          {
            path: '/user-center/bill/list',
            name: 'user-center-bill-list',
            component: UserCenterBillListView,
            meta: {
              module_name: 'bill',
            },
          },
          {
            path: '/user-center/bill/detail',
            name: 'user-center-bill-detail',
            component: UserCenterBillDetailView,
            meta: {
              module_name: 'bill',
            },
          },
          {
            path: '/user-center/bill/pay',
            name: 'user-center-bill-pay',
            component: UserCenterBillPayView,
            meta: {
              module_name: 'bill',
            },
          }
        ]
      },
      {
        path: '/user-center/order',
        name: 'user-center-order',
        component: UserCenterOrderIndexView,
        redirect: '/user-center/order/list',
        meta: {
          module_name: 'order',
        },
        children: [
          {
            path: '/user-center/order/list',
            name: 'user-center-order-list',
            component: UserCenterOrderListView,
            meta: {
              module_name: 'order',
            },
          },
          {
            path: '/user-center/order/detail',
            name: 'user-center-order-detail',
            component: UserCenterOrderDetailView,
            meta: {
              module_name: 'order',
            },
          }
        ],
      },
      {
        path: '/user-center/invoice',
        name: 'user-center-invoice',
        component: UserCenterInvoiceIndexView,
        redirect: '/user-center/invoice/view',
        meta: {
          module_name: 'invoice',
        },
        children: [
          {
            path: '/user-center/invoice/view',
            name: 'user-center-invoice-view',
            component: UserCenterInvoiceView,
            meta: {
              module_name: 'invoice',
            },
          },
        ],
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
