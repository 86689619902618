import request from "@/utils/request";
import API from "@/services/api";

interface smsCodeParams {
    phone: string
    ssid: string
    verify: string
}

export function smsCode(data: smsCodeParams) {
    return request({
        url: API.global.getSmsCode,
        method: "POST",
        data: data,
    })
}

export function imgCode() {
    return request({
        url: API.global.getImgCode,
        method: "GET",
    })
}

export interface registerParams {
    phone: string
    smsCode: string
    password: string
}

export function register(data: registerParams) {
    return request({
        url: API.user.register,
        method: "POST",
        data: data,
    })
}
export interface accountLoginParams {
    type: number
    username: string
    password: string
    smsCode?: number|null
    phone?: string
}

export function login(data: accountLoginParams) {
    return request({
        url: API.user.login,
        method: "POST",
        data: data,
    })
}

export function logout() {
    return request({
        url: API.user.logout,
        method: "GET",
    })
}

export interface resetPasswdParams {
    phone: string
    smsCode: string
    password: string
}

export function resetPasswd(data: resetPasswdParams) {
    return request({
        url: API.user.repwd,
        method: "POST",
        data: data,
    })
}

export function fetchUserInfo() {
    return request({
        url: API.user.userInfo.view,
        method: "GET",
    })
}

export interface updateUserInfoParams {
    companyName: string
    legalPerson: string
    legalPersonContact?: string
    head?: string
    headContact: string
    validDocuments: string
    creditCode: string
}


export function updateUserInfo(data: updateUserInfoParams) {
    return request({
        url: API.user.userInfo.update,
        method: "POST",
        data: data,
    })
}
