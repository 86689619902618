import request from "@/utils/request";
import API from "@/services/api";
interface fetchBillListParams {
    page: number,
    perPage: number,
}
export function fetchBillList(data: fetchBillListParams){
    return request({
        url: API.user.bill.list,
        method: "GET",
        params: data,
    })
}

interface fetchBillDetailParams {
    id: number,
}

export function fetchBillDetail(data: fetchBillDetailParams) {
    return request({
        url: API.user.bill.detail,
        method: "GET",
        params: data,
    })
}


interface fetchBillPayListParams {
    id: number,
}

export function fetchBillPayList(data: fetchBillPayListParams){
    return request({
        url: API.user.bill.payList,
        method: "GET",
        params: data,
    })
}


export interface fetchBillPayParams {
    billId: number, //	账单id
    detailIds: number[], //	array	账单明细勾选id，例：[1,2,3,4,5,6]
    money: string, //	支付账单金额
    cert: string[] //	上传凭证url地址
}

export function fetchBillPay(data: fetchBillPayParams) {
    return request({
        url: API.user.bill.pay,
        method: "POST",
        data: data,
    })
}
