import { createStore } from 'vuex'

export default createStore({
    state: {
        userInfo: {
            user_name: '',
        }
    },
    getters: {
    },
    mutations: {
        modifyName(state) {
            state.userInfo.user_name = "dasdas"
        }
    },
    actions: {
    },
    modules: {
    }
})
