import axios, {AxiosInstance, AxiosResponse} from "axios";
import {postSign} from "@/utils/request-sign";
import {CONFIG} from "@/configs/configs";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 对应国际化资源文件后缀
axios.defaults.headers["Content-Language"] = "zh_CN";

const HOST = CONFIG.HOST
const baseURL: string = CONFIG.baseURL

// 创建axios实例
const request: AxiosInstance = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: HOST+baseURL,
    // 超时
    timeout: 30000,
});

// request interceptor 请求之前拦截判断，权限等处理
request.interceptors.request.use((config) => {
    // 在请求发送前做一些处理，比如添加请求头或请求参数
    const token: string|null = localStorage.getItem(CONFIG.tokenKey)
    // const expires: string | null = localStorage.getItem(CONFIG.tokenKeyExpire);
    // config.headers['Access-Control-Allow-Origin'] = '*'
    // 处理POST请求添加签名
    if (config.method === "post") {
        config.data = {
            ...config.data,
            _sign: postSign(config.data)
        }
    }
    config.headers['Content-Language'] = null
    if (token) {
        config.headers.token = token
    } else {
        console.log('token-不存在')
    }

    return config
}, (error) => {
    // 请求错误时做一些处理
    console.log('请求错误', error)
    return Promise.reject(error);
})

// response interceptor
request.interceptors.response.use((response) => {
    // 在响应成功后做一些处理

    const result: AxiosResponse = response
    console.log('响应:',result)
    // 处理token失效的情况
    if (result.data && result.data.code === 100002) {
        localStorage.removeItem(CONFIG.tokenKey)
        window.location.href = '/'
    }

    return result
},(error) => {
    // 在响应错误时做一些处理
    console.log('响应错误', error)
    return Promise.reject(error);
})

export default request
