<script setup lang="ts">

</script>

<template>
    <div class="layout-privacy-protocol-box">
        <div>
            <div class="title">问仓隐私政策</div>
            <div class="create_date">生效日期：2023年12月04日</div>
            <div class="update_date">更新日期：2023年12月04日</div>
            <div class="main-box">
                <div>
                    尊敬的问仓平台用户：问仓平台（北京问仓天下科技有限公司，以下亦称 “问仓平台”或“ <span class="bold">我们</span> ”）非常重视客户个人信息及隐私权的保护，因此我们制订了涵盖如何收集、存储、使用、共享和保护客户信息的隐私政策。<span class="bold">《问仓平台隐私政策》（以下简称 “本隐私政策”）适用于问仓平台 。</span>
                </div>
                <div>
                    <span class="bold">请仔细阅读本隐私政策的全部条款（尤其是以加粗方式显示的内容），确认您已完全了解我们对您个人信息的处理规则，并作出您认为适当的选择。阅读过程中，如您有任何疑问，可联系我们的客服咨询，如您不同意协议中的任何条款，您应立即停止访问或使用问仓平台。</span>
                </div>
                <div>
                    <span class="one-title">第一部分 定义及本隐私政策的适用范围</span>
                </div>
                <div>
                    <span class="bold">问仓平台：</span> 指北京问仓天下科技有限公司（以下简称 “本公司”）旗下的，包括问仓网站（www.51wencang.com)等在内的大部分产品与服务（以下简称“本产品”）。问仓平台通过聚合中小企业主的仓库资源，充分利用互联网技术连接产业链上下游，来更好满足物流公司、电商、传统零售连锁、高端制造等企业的仓储运营需求，从而提高物流地产的经营效率 。
                </div>
                <div>
                    <span class="bold">问仓平台服务提供者：</span> 指问仓平台的互联网信息及软件技术服务提供者北京问仓天下科技有限公司（注册地址为北京市通州区榆西一街1号院4号楼5层501室1017）。
                </div>
                <div>
                    <span class="bold">个人信息：</span>  指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
                </div>
                <div>
                    <span class="bold">个人敏感信息：</span> 指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、 14岁以下（含）儿童信息等的个人信息（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。
                </div>
                <div>
                    <span class="bold">个人信息删除： </span> 指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
                </div>
                <div>
                    <span class="bold">本隐私政策的适用范围：</span> 适用于问仓平台服务提供者以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如我们及关联公司的产品或服务中使用了问仓平台的产品或服务但未设独立隐私政策的，则本政策同样适用于该部分产品或服务。我们及关联公司就其向您提供的产品或服务单独设有隐私政策的，则相应产品或服务适用相应隐私政策。
                </div>
                <div>
                    除本政策说明的相关信息收集使用活动外，本政策不适用于其他第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。
                </div>
                <div>
                    <span class="one-title">第二部分 本隐私政策将帮助您了解以下内容：</span>
                </div>
                <div>
                    一、信息的收集和使用
                </div>
                <div>
                    二、我们如何使用 Cookie 和同类技术
                </div>
                <div>
                    三、我们如何共享、转让、公开披露您的个人信息
                </div>
                <div>
                    四、我们如何保护和保存您的个人信息
                </div>
                <div>
                    五、您如何管理您的个人信息
                </div>
                <div>
                    六、未成年人的个人信息保护
                </div>
                <div>
                    七、通知和修订
                </div>
                <div>
                    八、如何联系我们
                </div>
                <div>
                    <span class="two-title">一、信息的收集和使用</span>
                </div>
                <div>
                    <span class="bold">为本隐私政策之目的，个人信息包括但不限于（其中，个人敏感信息以加粗方式显示）; 基本信息（包括个人姓名、生日、性别、住址、 个人电话号码 、 手机号码 、 电子邮箱 ）； 个人身份信息（包括姓名 、 身份证 、 银行卡 、 营业执照 、 有关身份证明的信息 等 ） ；网络身份标识信息（包括 电子签名 、 系统账户 、 账户名 、 账户昵称 、IP地址、 邮箱地址及与前述有关的密码 ）； 个人财产信息（房产信息、房源交易信息、房源租赁信息、交易记录、交易账户、问仓信用记录等虚拟财产信息）； 其他信息（包括通讯录行程信息、 网页浏览记录、精准定位信息 ）。</span>
                </div>
                <div>
                    在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
                </div>
                <div>
                    <span class="bold"> 1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务，我们的基本业务功能，包括注册用户服务、实现信息搜索和展示、客服及争议解决、与指定合作范围内的合作机构、合作平台之间共享房源、客源信息 、 保障交易安全所必须的功能；</span>
                </div>
                <div>
                    <span class="bold">2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。</span>
                </div>
                <div>
                    <span class="bold">您理解并同意：</span>
                </div>
                <div>
                    <span class="bold">1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应地，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；</span>
                </div>
                <div>
                   <span class="bold"> 2、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本隐私政策、以弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。</span>
                </div>
                <div>
                    在此过程中，如果您有任何疑问、意见或建议的，您可通过问仓平台官方客服与我们联系，我们会尽快为您作出解答。
                </div>
                <div>
                    为本隐私政策之目的，我们仅会出于以下核心业务功能，收集和使用您的个人信息，如果您不提供相关信息，您将无法享受我们提供的产品和/或服务：
                </div>
                <div>
                    <span class="bold"> （一）  帮助您成为我们的注册用户</span>
                </div>
                <div>
                    <span class="bold"> 1、  基础用户服务</span>
                </div>
                <div>
                    我们通过问仓平台账户为您提供基础用户服务。为注册成为用户并使用我们的基础服务，您需要提供<span class="bold">手机号码</span>和<span class="bold">密码</span>用于创建问仓平台账户。如果您仅需使用浏览、搜索服务，您不需要注册成为我们的用户及提供上述信息。
                </div>
                <div>
                    对于需要通过问仓平台用户账户才能使用的服务，我们可能会根据您提供的上述信息校验您的用户身份，确保我们是在为您本人提供服务。
                </div>
                <div>
                    如您选择短信验证码方式登录，您授权同意我们调用您的短信接收和处理功能，向您发送短信以验证您的身份。
                </div>
                <div>
                    <span class="bold">2、附加用户服务</span>
                </div>
                <div>
                    实名认证：您可以根据认证要求向我们提供相应的<span class="bold">身份信息（身份证、护照、户口本及其他身份证件信息）、银行卡信息</span>以完成实名认证。
                </div>
                <div>
                    授权登录：我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的问仓平台账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及 /或服务。我们将在您授权同意的范围内使用您的相关信息。
                </div>
                <div>
                    <span class="bold">3、账户信息展示 ：</span>如果您已拥有问仓平台账户，我们可能会在问仓平台服务中显示您的上述个人信息，以及您在问仓平台上或与问仓平台账户相关联的产品和服务中执行的操作，包括通过问仓平台账户集中展示您的个人资料、问仓积分、在租在售房源、提供的物流托管服务等。我们会尊重您对问仓平台服务和问仓平台账户设置所做的选择。
                </div>
                <div>
                    <span class="bold">（二）实现信息搜索和展示所必须的功能</span>
                </div>
                <div>
                    为了展示您拟出售/出租的房源，我们可能会收集您与房源相关的信息（包括<span class="bold">您及房源权利人身份信息、联系电话、房屋位置信息 、 房屋交易信息等</span>）。
                </div>
                <div>
                    在您使用我们服务过程中，为识别账户异常状态、了解产品适配性、保障用户交易安全、向您提供更契合您需求的页面展示和搜索结果、改进和优化前述功能，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：
                </div>
                <div>
                    设备信息：我们会根据您在访问及 /或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA等设备标识符、设备环境、移动应用列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的<span class="bold">GPS位置</span>以及WLAN接入点、蓝牙和基站等传感器信息）。
                </div>
                <div>
                    服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、看房记录、交易、售后、关注分享信息、发布信息，以及 IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。
                </div>
                <div>
                    <span class="bold">请您注意，如您的搜索关键词信息无法识别您的身份，其将不属于您的个人信息，我们有权在法律法规允许的范围内对其进行自主使用；只有当您的搜索关键词信息单独或与您的其他信息相互结合使用并可以识别您的身份时，则在单独或结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span>
                </div>
                <div>
                    <span class="bold">为向您提供更便捷、更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的设备信息和服务日志信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告。</span>
                </div>
                <div>
                    如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。在您使用我们提供的站内搜索服务时，我们也同时提供了不针对您个人特征的选项。
                </div>
                <div>
                    <span class="bold">（三）客户服务及争议处理</span>
                </div>
                <div>
                    当您需要我们提供客户服务时，我们的客服（包括呼叫中心及在线客服）会使用您的相关信息，账户信息。
                </div>
                <div>
                    为保证您的账户安全，我们的呼叫中心客服和在线客服会使用您的账户信息对您的身份进行核验。待您的身份核验后，我们将会查询您的相关信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更联系人或联系电话。
                </div>
                <div>
                    为保障您的合法权益，保证我们的客户服务合法合规，并持续改进我们的服务能力，我们会对您与客服沟通的过程进行录音或文字留存，该留存信息仅会在法律所允许的期限和范围内予以留存和处理，并仅在基于您的维权需要、政府部门及司法部门的调查指令需要提供时，我们会进行必要披露。
                </div>
                <div>
                    为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
                </div>
                <div>
                    <span class="bold"> （四）保障交易安全所必须的功能</span>
                </div>
                <div>
                    为提高您使用我们的产品与 /或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的设备信息（如设备型号、设备识别码、设备标识符）、您的网络使用习惯（如浏览信息、常用软件信息）、手机号在网状态等手段来判断您的账户风险，并可能会记录一些我们认为有风险的链接（“URL”）。
                </div>
                <div>
                    <span class="bold">（五）您可选择是否授权我们收集和使用您的个人信息的情形</span>
                </div>
                <div>
                    1、为使您使用问仓平台更便捷或更有乐趣，从而提升您在问仓的体验，我们的以下附加功能中可能会收集和使用您的个人信息。<span class="bold">如果您不提供这些个人信息，您依然可以使用问仓的产品或服务中的核心功能，但您可能无法使用这些可以为您所带来更多乐趣的附加功能或在使用某些产品或服务时需要重复填写一些信息。</span>这些附加功能包括：
                </div>
                <div>
                    （1）个性化推荐功能： <span class="bold">我们可能会收集您的看仓记录、浏览信息、您设备的位置信息、您向问仓提供的兴趣爱好等进行数据分析以形成用户画像，用来将您感兴趣的房源、商品或服务信息通过展示给您；或在您搜索时向您展示您可能希望找到的商品。若您想管理我们给您发送的个性化推荐的房源、商品和服务信息，您可以联系我们进行设置。需注意的是，您可设置我们不使用您得偏好特征向您推荐、展示相关房源，但是您仍然会在相关推荐板块内看到房源信息，且房源信息的数量不会变化，只不过房源信息的相关性会下降。我们会收集您的位置信息来判断您所处的地点，自动为您推荐您所在区域可以浏览的房源、可购买的商品或服务，或向您推荐离您最近的可租房源。</span>
                </div>
                <div>
                    （2）基于摄像头（相机）的附加功能：您可以使用这个附加功能完成视频拍摄、拍照、扫码的功能。
                </div>
                <div>
                    （3）基于发送短信的附加功能：您可以通过短信接收您在问仓平台上订阅的房源信息；可以将问仓中的优质房源信息通过短信、微信等第三方通讯工具发送给您的好友。
                </div>
                <div>
                    （ 4）拨打电话的附加功能：如您对问仓中的房源感兴趣，您可以直接在我们房源页面专属功能拨打电话联系房源维护人，我们会记录并使用加密功能隐藏您的真实电话号码。我们会对您与房源维护人或其他经纪人沟通的过程进行录音或文字留存，该留存信息仅会在法律所允许的期限和范围内予以留存和处理，并仅在基于您的维权需要、政府部门、问仓认证的合作伙伴及司法部门的调查指令需要提供时，我们会进行必要披露。
                </div>
                <div>
                    （ 5）改进我们的产品与/或服务：为了提供服务及改进服务质量的合理需要，我们可能会获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间进行互动时授权我们获得的相关信息。
                </div>
                <div>
                    2、上述附加功能可能需要您在您的设备中向我们开启您的<span class="bold">地理位置（位置信息）、相机（摄像头）、相册（图片库）、麦克风 的访问权限， 以实现这些功能所涉及的信息的收集和使用 。 请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span>
                </div>
                <div>
                    <span class="bold">（六）您充分知晓，以下情形中，我们收集、使用个人信息可能无需征得您的授权同意：</span>
                </div>
                <div>
                    <span class="bold">1、与国家安全、国防安全直接相关的；</span>
                </div>
                <div>
                    <span class="bold">2、与公共安全、公共卫生、重大公共利益直接相关的；</span>
                </div>
                <div>
                    <span class="bold">3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</span>
                </div>
                <div>
                    <span class="bold">4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
                </div>
                <div>
                    <span class="bold">5、所收集的个人信息是您自行向社会公众公开的；</span>
                </div>
                <div>
                    <span class="bold">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开、工商注册等渠道；</span>
                </div>
                <div>
                   <span class="bold"> 7、根据您的要求签订和履行合同所必需的；</span>
                </div>
                <div>
                    <span class="bold"> 8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；9、法律法规规定的其他情形。</span>
                </div>
                <div>

                    <span class="bold"> （七）我们从第三方获得您个人信息的情形</span>
                </div>
                <div>
                    我们可能从第三方获取您授权共享的账户信息（头像、昵称、手机号等），并在您同意本隐私政策后将您的第三方账户与您的问仓账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与 /或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
                </div>
                <div>
                    <span class="bold"> （八）您个人信息使用的规则</span>
                </div>
                <div>
                    1、我们会根据本隐私政策的约定，在实现我们的产品与/或服务功能所必要的范围内，对所收集的个人信息进行使用。
                </div>
                <div>
                    2、在收集您的个人信息后，我们可能将通过技术手段对部分数据进行匿名化处理，匿名化处理的信息将无法识别主体。 <span class="bold"> 请您了解并同意，在此情况下我们有权使用已经匿名化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span>
                </div>
                <div>
                    <span class="bold"> 3、 请您注意，您在使用我们的产品与 /或服务时所提供的所有个人信息，除非您删除、通过系统设置或联系我们等方式撤回您的同意，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账户时，我们将停止使用并删除您的个人信息。</span>
                </div>
                <div>
                    4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势等。但这些统计信息不包含您的任何身份识别信息。
                </div>
                <div>
                    5、当我们展示您的个人信息时，我们会采用包括内容替换、去标识化处理等方式对您的信息进行脱敏，以保护您的信息安全。
                </div>
                <div>
                    6、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会要求您通过主动勾选的形式事先征求您的同意。
                </div>
                <div>
                    <span class="two-title"> 二、我们如何使用 Cookie 和同类技术</span>
                </div>
                <div>
                    <span class="bold"> （一）Cookies的使用</span>
                </div>
                <div>
                    1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的偏好或您浏览历史等数据进而为您提供偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。
                </div>
                <div>
                    2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问问仓网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
                </div>
                <div>
                    <span class="bold"> （二）网络Beacon和同类技术的使用</span>
                </div>
                <div>
                    除 Cookie 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF 文件或 "网络 beacon"）。我们使用网络beacon的方式有：
                </div>
                <div>
                    1、通过在问仓使用网络beacon，计算用户访问数量，并通过访问 cookie 辨认注册的问仓用户。
                </div>
                <div>
                    2、通过得到的cookies信息，为您提供个性化服务。
                </div>
                <div>
                    <span class="two-title"> 三、我们如何共享、转让、公开披露您的个人信息</span>
                </div>
                <div>
                    <span class="bold"> （一）共享</span>
                </div>
                <div>
                   <span class="bold">  1、我们不会与问仓以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</span>
                </div>
                <div>
                    <span class="bold"> （1）事先获得您明确的同意或授权；</span>
                </div>
                <div>
                    <span class="bold"> （2）与国家安全、国防安全直接相关的；</span>
                </div>
                <div>
                    <span class="bold"> （3）与公共安全、公共卫生、重大公共利益直接相关的；</span>
                </div>
                <div>
                    <span class="bold"> （4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</span>
                </div>
                <div>
                    <span class="bold"> （5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
                </div>
                <div>
                    <span class="bold"> （6）自行向社会公众公开的个人信息；</span>
                </div>
                <div>
                    <span class="bold"> （7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开、工商注册等渠道。</span>
                </div>
                <div>
                   <span class="bold">  2、经您授权后，我们可能会将您的个人信息与我们的关联方共享。我们只会共享必要的个人信息，并要求关联方以不低于本隐私政策水平的方式保护您的个人信息。我们对该等关联方提供的服务性质与质量不作任何承诺或承担任何责任，对该等关联方独立进行的用户信息采集和处理行为也不作任何承诺或承担任何责任。有关您在使用关联方服务中相关的隐私控制和选项，以及服务提供者的隐私规定，请您谨慎参阅该等关联方所提供的隐私政策。</span>
                </div>
                <div>
                   <span class="bold">  3、经您授权后，我们可能会向合作伙伴等第三方共享您的账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。 我们的合作伙伴包括以下类型：</span>
                </div>
                <div>
                    （1）商品或技术服务的供应商。 <span class="bold">我们可能会将您的个人信息共享给支持我们功能的第三方。</span> 这些支持包括为我们的提供授权房源、供货或提供基础设施技术服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们的核心功能。
                </div>
                <div>
                    （2）第三方商家。 <span class="bold">您知悉并理解，问仓平台上的部分服务是由第三方商家提供的；在您选择通过问仓平台使用该等第三方以其独立的名义提供的相关服务时，我们要根据您的授权同意将必要的信息提供至该等第三方，以协助您使用第三方所提供的服务。</span> 例如：我们需要将您的托管商委托信息与相应托管服务商共享以为您提供托管服务。
                </div>
                <div>
                    就上述第三方向您提供的服务，我们对该等第三方提供的服务性质与质量不作任何承诺或承担任何责任，对该等第三方进行的用户信息采集和处理行为也不作任何承诺或承担任何责任。有关您在使用第三方服务中相关的隐私控制和选项，以及服务提供者的隐私规定，请您谨慎参阅该等第三方所提供的隐私政策。
                </div>
                <div>
                    （3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。 <span class="bold">我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（ “委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。</span> 比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
                </div>
                <div>
                    4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
                </div>
                <div>
                    5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护问仓、您或其他问仓客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息，比如为了确认您的履约能力，我们会向第三方征信机构提供您的<span class="bold"> 身份证件信息</span> 以获取 <span class="bold">您在征信机构的个人信用 。</span>前述共享行为并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
                </div>
                <div>
                    <span class="bold">（二）转让与公开披露</span>
                </div>
                <div>
                    <span class="bold">在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
                </div>
                <div>
                    除本隐私政策所明确述明的情况以外，我们不会将您的个人信息转让或公开披露给任何公司、组织和个人，但以下情况除外：
                </div>
                <div>
                    1、与国家安全、国防安全直接相关的；
                </div>
                <div>
                    2、与公共安全、公共卫生、重大公共利益直接相关的；
                </div>
                <div>
                    3、与犯罪侦查、起诉、审判和判决执行等直接相关的；
                </div>
                <div>
                    4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
                </div>
                <div>
                    5、您向社会公众公开的个人信息；
                </div>
                <div>
                    6、从合法公开披露的信息中收集的您的个人信息，如合法的新闻报道、政府信息公开、工商注册等渠道。
                </div>
                <div>
                    <span class="two-title">四、我们如何保护和保存您的个人信息</span>
                </div>
                <div>
                    <span class="bold">（一）我们保护您个人信息的技术与措施</span>
                </div>
                <div>
                    我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
                </div>
                <div>
                    1、数据安全技术措施
                </div>
                <div>
                    问仓采取安全技术和管理措施来防止您的个人信息遭到未经授权访问使用、公开披露、使用、修改、损坏或丢失，包括但不限于：
                </div>
                <div>
                    （ 1）问仓采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换在内多种数据脱敏技术增强个人信息在使用中安全性；
                </div>
                <div>
                    （2）问仓采取严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用；
                </div>
                <div>
                    （3）问仓采取数据访问日志分析技术进行个人信息安全审计。
                </div>
                <div>
                    2、问仓为保护个人信息采取的其他安全措施
                </div>
                <div>
                    安全制度保障。问仓通过制度规范问仓各部门对个人信息所采取的管理措施。加强安全意识。我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
                </div>
                <div>
                    3、我们建议您使用电子邮件、即时通讯、社交软件等交流工具时使用复杂密码，并注意保护您的个人信息安全。
                </div>
                <div>
                    4、我们将尽力确保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
                </div>
                <div>
                    5、安全事件处置
                </div>
                <div>
                    为应对个人信息泄露、损毁和丢失的风险，问仓制定了多项制度并成立了应急响应团队。我们按照安全事件的处置规范，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
                </div>
                <div>
                    在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
                </div>
                <div>
                    6、如果您发现自己的个人信息泄密或我们存在有安全弱点、可疑安全事件时，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策最下方约定的联系方式联络我们，以便我们采取相应措施，禁止私自尝试或验证弱点。
                </div>
                <div>
                    7、如果您对我们的个人信息保护有任何疑问，可通过本隐私政策最下方约定的联系方式联系我们。
                </div>
                <div>
                    <span class="bold">（二）您个人信息的保存</span>
                </div>
                <div>
                    1、您的个人信息将会被存储于中华人民共和国境内。目前，问仓平台的产品和服务不会涉及到数据的跨境传输，如果我们的产品/服务发生变更，涉及数据的跨境传输，我们会单独向您以弹窗或邮件的方式告知您数据出境的目的及接收方，并征得您的授权同意，我们会确保数据接收方有充足的数据保护能力来保护您的个人信息。
                    2、我们仅在您使用我们的服务期间和法律法规要求的最短时限内保留您的个人信息。对于超出期限的个人信息，我们会立即删除或做匿名化处理。
                </div>
                <div>
                    <span class="bold">3、 如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</span>
                </div>
                <div>
                    <span class="two-title"> 五、您如何管理您的个人信息</span>
                </div>
                <div>
                    问仓非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
                </div>
                <div>
                    <span class="bold">1、访问和更正您的个人信息</span>
                </div>
                <div>
                    除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：
                </div>
                <div>
                    （ 1）您的账户信息：您可以在“我的”页面中查阅您提交给问仓的所有个人信息，您也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息）。
                </div>
                <div>
                    （2）您的浏览信息：您可以查看和修改订阅信息以及管理其他数据。
                </div>
                <div>
                    （3）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
                </div>
                <div>
                    （ 4）您无法访问和更正的个人信息：除上述列明的信息外，考虑到不响应请求可能给您合法权益带来的风险和损害、技术可行性及实现请求成本等因素，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用附加功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。
                </div>
                <div>
                    <span class="bold">2、删除您的个人信息</span>
                </div>
                <div>
                    您在我们的产品与/或服务页面中可以在“我的”页面中，删除您的部分信息。
                </div>
                <div>
                    在以下情形中，您可以通过与客服联系向我们提出删除个人信息的请求：
                </div>
                <div>
                    （1）如果我们处理个人信息的行为违反法律法规；
                </div>
                <div>
                    （2）如果我们收集、使用您的个人信息，却未征得您的同意；
                </div>
                <div>
                    （3）如果我们处理个人信息的行为违反了与您的约定；
                </div>
                <div>
                    （4）如果我们终止服务及运营。
                </div>
                <div>
                    若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
                </div>
                <div>

                    当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
                </div>
                <div>
                    <span class="bold"> 3、改变您授权同意的范围或撤回您的授权</span>
                </div>
                <div>
                    您可以通过删除信息、关闭设备功能、在问仓网站或软件中进行设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。
                </div>
                <div>
                    对于您无法直接通过上述方式设置的授权，您可以通过客服联系进行修改，但请注意，对于部分类型的个人信息，例如实现 问仓平台基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。
                </div>
                <div>
                    <span class="bold">请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
                </div>
                <div>
                    <span class="bold">4、注销账户</span>
                </div>
                <div>
                    您可以通过客服联系，申请注销您的账户。
                </div>
                <div>
                    在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。
                </div>
                <div>
                    <span class="bold">5、响应您的请求</span>
                </div>
                <div>
                    如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与 /或服务时所产生的其他个人信息，或您认为问仓存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以与我们联系。
                </div>
                <div>
                    为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的 15日内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
                </div>
                <div>
                    <span class="bold">在以下情形中，按照法律法规要求，我们将可能无法响应您的请求：</span>
                </div>
                <div>
                    <span class="bold">（1）与国家安全、国防安全直接相关的；</span>
                </div>
                <div>
                    <span class="bold">（2）与公共安全、公共卫生、重大公共利益直接相关的；</span>
                </div>
                <div>
                    <span class="bold">（3）与犯罪侦查、起诉、审判和执行判决等直接相关的；</span>
                </div>
                <div>
                    <span class="bold">（4）有充分证据表明您存在主观恶意或滥用权利的；</span>
                </div>
                <div>
                    <span class="bold">（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span>
                </div>
                <div>
                    <span class="bold">（6）涉及商业秘密的。</span>
                </div>
                <div>
                    <span class="two-title">六、未成年人的个人信息保护</span>
                </div>
                <div>
                    1、问仓非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您家长或法定监护人的书面同意。问仓根据国家相关法律法规的规定保护未成年人的个人信息。
                </div>
                <div>
                    2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
                </div>
                <div>
                    3、如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
                </div>
                <div>
                    <span class="two-title">七、通知和修订</span>
                </div>
                <div>
                    1、为给您提供更好的服务以及随着问仓业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在问仓平台上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问问仓以便及时了解最新的隐私政策。
                </div>
                <div>
                    2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
                </div>
                <div>
                    <span class="bold">本隐私政策所指的重大变更包括但不限于：</span>
                </div>
                <div>
                    <span class="bold">（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
                </div>
                <div>
                    <span class="bold">（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span>
                </div>
                <div>
                    <span class="bold">（3）个人信息共享、转让或公开披露的主要对象发生变化；</span>
                </div>
                <div>
                    <span class="bold">（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
                </div>
                <div>
                    <span class="bold">（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
                </div>
                <div>
                    <span class="bold">（6）个人信息安全影响评估报告表明存在高风险时。</span>
                </div>
                <div>
                    3、我们还会将本策略的旧版本存档，供您查阅。
                </div>
                <div>
                    <span class="two-title">八、如何联系我们</span>
                </div>
                <div>
                    1、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请通过发送邮件或拨打我们的客服电话等多种方式与我们联系。
                </div>
                <div>
                    2、一般情况下，我们将在十五日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及市场监督管理等监管部门进行投诉或举报，或者向有管辖权的法院提起诉讼。
                </div>
                <div>
                    <span class="bold">我们谨此再次提醒您，本隐私政策内容中以加粗方式显著标识的条款，请您着重阅读。您点击 “注册”/“登录”按钮即视为您完全接受本隐私政策，在点击之前请您再次确认已知悉并完全理解本隐私政策的全部内容。</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.layout-privacy-protocol-box{
    display: block;
    width: auto;
    max-width: 1200px;
    padding: 0 20px;
    margin: 20px auto;

    .title{
        display: block;
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-weight: bold;
    }

    .create_date,
    .update_date {
        display: block;
        width: 100%;
        text-align: right;
        font-size: 13px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        margin-bottom: 5px;
    }

    .main-box{
        display: block;
        width: 100%;
        height: auto;
        padding-bottom: 100px;
        margin-top: 20px;

        div {
            display: block;
            text-align: justify;
            font-size: 15px;
            color: rgb(0, 0, 0);
            margin-bottom: 5px;

            .one-title{
                display: block;
                text-align: left;
                font-size: 30px;
                color: rgb(0, 0, 0);
                font-weight: bold;
                margin: 30px 0;

            }

            .two-title{
                display: block;
                text-align: left;
                font-size: 20px;
                color: rgb(0, 0, 0);
                font-weight: bold;
                margin: 15px 0;
            }

            .bold{
                font-weight: bold;
            }
        }
    }
}
</style>
