import {CONFIG} from "@/configs/configs";

const API: any = {
    host: CONFIG.HOST,
    global: {
        provinceList: "/pc/store/get-province-list",
        cityList: "/pc/store/get-city-list",
        areaList: "/pc/store/get-district-list",
        getSmsCode: "/pc/public/get-sms-code",
        getImgCode: "/pc/public/get-verify",
        fieldDictionary: "/pc/store/get-store-dict",
        aliOSSSign: "/pc/upload/sign",
        setCity: "/pc/store/add-city",
    },
    user: {
        register: "/pc/user/reg",
        login: "/pc/user/login",
        logout: "/pc/user/sign-out",
        repwd: "/pc/user/forget-password",
        userInfo: {
            view: "/pc/user/get-user-info",
            update: "/pc/user/update-user-info",
        },
        collect: {
            list: "/pc/favorites/index",
            cancel: "/pc/favorites/delete",
            save: "/pc/favorites/save",
        },
        contract: {
            auth: "/pc/contract/get-verify-url",
            list: "/pc/contract/get-list",
            sign: "/pc/contract/sign-contract",
            detail: "/pc/contract/detail",
            signCallBack: "/api/public/contract-sign-callback",
            authCallBack: "/api/public/contract-verify-callback",
        },
        bill: {
            list: "/pc/bill/list",
            detail: "/pc/bill/pay-info",
            payList: "/pc/bill/pay-list",
            pay: "/pc/bill/pay",
        },
        order: {
            create: "/pc/contract/order",
            list: "/pc/order/list",
            detail: "/pc/order/detail",
            reportMoney: "/pc/order/view-report",
        },
        invoice: {
            list: "/pc/invoice/invoice-bill-detail-list",
            record: "/pc/invoice/list",
            info: "/pc/invoice-base/list",
            headType: "/pc/invoice-base/get-head-types",
            invoiceType: "/pc/invoice-base/get-types",
            save: "/pc/invoice-base/save",
            delete: "/pc/invoice-base/delete",
            defaultInfo: "/pc/invoice-base/set-is-main",
            apply: "/pc/invoice/save",
            applyRemark: '/pc/invoice/save-remark',
            detail: "/pc/invoice/info",
        }
    },
    home: {
        bannerList: "/pc/banner/index",
        goodStoreList: "/pc/store/get-high-quality",
        authStoreList: "/pc/store/get-authentication",
        positionList: "/pc/store/get-position-city",
    },
    other: {
        entrustStore: "/pc/need/add",
        applyJoin: "/pc/join-apply/add",
    },
    store: {
        list: '/pc/store/get-list',
        detail: '/pc/store/detail',
        dictList: "/pc/store/get-store-dict",
    },
}

export default API
